"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.prospectFromResumeAddMutation = exports.ProspectFromResumeAddDocument = exports.freelanceResumeAddMutation = exports.FreelanceResumeAddDocument = exports.freelanceMergeCareersMutation = exports.FreelanceMergeCareersDocument = exports.CarreerDataFragmentDoc = exports.ResumeDataFragmentDoc = void 0;
const graphql_vue_apollo_patch_1 = require("@frk/graphql-vue-apollo-patch");
const vue_apollo_smart_ops_1 = require("vue-apollo-smart-ops");
exports.ResumeDataFragmentDoc = (0, graphql_vue_apollo_patch_1.gql) `
    fragment ResumeData on Freelance {
  resume {
    url
    updatedAt
    parsedAt
  }
}
    `;
exports.CarreerDataFragmentDoc = (0, graphql_vue_apollo_patch_1.gql) `
    fragment CarreerData on Freelance {
  experiences {
    id
    actual
    description
    startDate
    endDate
    job
    location
    name
  }
  educations {
    id
    description
    institution
    name
    year
  }
}
    `;
exports.FreelanceMergeCareersDocument = (0, graphql_vue_apollo_patch_1.gql) `
    mutation freelanceMergeCareers($career: CareerInput!, $userId: ID!) {
  freelanceMergeCareers(career: $career, userId: $userId) {
    _id
    freelance {
      ...CarreerData
    }
  }
}
    ${exports.CarreerDataFragmentDoc}`;
/**
 * __freelanceMergeCareersMutation__
 *
 * To run a mutation, you call `freelanceMergeCareersMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = freelanceMergeCareersMutation(this, {
 *   variables: {
 *     career: // value for 'career'
 *     userId: // value for 'userId'
 *   },
 * });
 */
exports.freelanceMergeCareersMutation = (0, vue_apollo_smart_ops_1.createMutationFunction)(exports.FreelanceMergeCareersDocument);
exports.FreelanceResumeAddDocument = (0, graphql_vue_apollo_patch_1.gql) `
    mutation freelanceResumeAdd($fileUrl: String!, $userId: ID) {
  freelanceResumeAdd(fileUrl: $fileUrl, userId: $userId) {
    _id
    freelance {
      ...CarreerData
      ...ResumeData
    }
  }
}
    ${exports.CarreerDataFragmentDoc}
${exports.ResumeDataFragmentDoc}`;
/**
 * __freelanceResumeAddMutation__
 *
 * To run a mutation, you call `freelanceResumeAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = freelanceResumeAddMutation(this, {
 *   variables: {
 *     fileUrl: // value for 'fileUrl'
 *     userId: // value for 'userId'
 *   },
 * });
 */
exports.freelanceResumeAddMutation = (0, vue_apollo_smart_ops_1.createMutationFunction)(exports.FreelanceResumeAddDocument);
exports.ProspectFromResumeAddDocument = (0, graphql_vue_apollo_patch_1.gql) `
    mutation prospectFromResumeAdd($input: ProspectFromResumeAddInput!) {
  prospectFromResumeAdd(input: $input) {
    _id
    freelance {
      ...CarreerData
      ...ResumeData
    }
  }
}
    ${exports.CarreerDataFragmentDoc}
${exports.ResumeDataFragmentDoc}`;
/**
 * __prospectFromResumeAddMutation__
 *
 * To run a mutation, you call `prospectFromResumeAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = prospectFromResumeAddMutation(this, {
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
exports.prospectFromResumeAddMutation = (0, vue_apollo_smart_ops_1.createMutationFunction)(exports.ProspectFromResumeAddDocument);
