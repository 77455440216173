"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserFindByNameOrEmailQuery = exports.UserFindByNameOrEmailDocument = void 0;
const graphql_vue_apollo_patch_1 = require("@frk/graphql-vue-apollo-patch");
const vue_apollo_smart_ops_1 = require("vue-apollo-smart-ops");
exports.UserFindByNameOrEmailDocument = (0, graphql_vue_apollo_patch_1.gql) `
    query userFindByNameOrEmail($firstname: String, $lastname: String, $email: String) {
  userFindByNameOrEmail(firstname: $firstname, lastname: $lastname, email: $email) {
    _id
    firstname
    lastname
    email
    roles
  }
}
    `;
/**
 * __useUserFindByNameOrEmailQuery__
 *
 * To use a Smart Query within a Vue component, call `useUserFindByNameOrEmailQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     userFindByNameOrEmail: useUserFindByNameOrEmailQuery({
 *       variables: {
 *         firstname: // value for 'firstname'
 *         lastname: // value for 'lastname'
 *         email: // value for 'email'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
exports.useUserFindByNameOrEmailQuery = (0, vue_apollo_smart_ops_1.createSmartQueryOptionsFunction)(exports.UserFindByNameOrEmailDocument);
