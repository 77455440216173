"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const xstate_1 = require("xstate");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FrkFileUploader',
    props: {
        accept: null,
        dataTest: null,
        maxSize: null,
        signUpload: null,
        uploadFile: null,
        updateUser: null,
        disabled: { type: Boolean },
        small: { type: Boolean }
    },
    emits: ["file-dropped"],
    setup(__props, { emit }) {
        const props = __props;
        const $sentry = (0, vue_2.getCurrentInstance)().proxy.$sentry;
        const $store = (0, vue_2.getCurrentInstance)().proxy.$store;
        const onError = {
            target: 'idle',
            actions: (_, event) => {
                // GraphQL Errors are handled by Sentry (error link)
                // but Axios errors are not -> report them
                if (event.data.isAxiosError) {
                    $sentry.captureException(event.data);
                }
                $store.dispatch('messages/pushFlashMessage', {
                    message: 'Erreur lors du téléchargement du fichier ! Veuillez réessayer.',
                    colorClass: 'invalid',
                });
            },
        };
        const machine = (0, xstate_1.createMachine)({
            id: 'file-upload',
            initial: 'idle',
            context: {
                signatureData: undefined,
                file: undefined,
                fileId: undefined,
            },
            states: {
                idle: {
                    on: {
                        DROP_FILE: [
                            {
                                target: 'signingUpload',
                                actions: (0, xstate_1.assign)({
                                    file: (_, event) => event.file,
                                }),
                                cond: (_, event) => isBelowMaxSize(event.file),
                            },
                            {
                                actions: () => $store.dispatch('messages/pushFlashMessage', {
                                    message: 'Fichier trop volumineux ! Veuillez réessayer avec un autre fichier.',
                                    colorClass: 'invalid',
                                }),
                                target: 'idle',
                                cond: (_, event) => !isBelowMaxSize(event.file),
                            },
                        ],
                    },
                },
                signingUpload: {
                    invoke: {
                        id: 'signUpload',
                        src: context => props.signUpload({ file: context.file }),
                        onDone: {
                            target: 'uploadingFile',
                            actions: (0, xstate_1.assign)({
                                signatureData: (_, event) => event.data,
                            }),
                        },
                        onError,
                    },
                },
                uploadingFile: {
                    invoke: {
                        id: 'uploadFile',
                        src: context => props.uploadFile({
                            signatureData: context.signatureData,
                            file: context.file,
                        }),
                        onDone: {
                            target: 'updatingUser',
                            actions: (0, xstate_1.assign)({
                                fileId: (_, event) => event.data,
                            }),
                        },
                        onError,
                    },
                },
                updatingUser: {
                    invoke: {
                        id: 'updateUser',
                        src: context => props.updateUser({
                            fileId: context.fileId,
                            signatureData: context.signatureData,
                        }),
                        onDone: {
                            target: 'idle',
                            actions: () => $store.dispatch('messages/pushFlashMessage', {
                                message: 'Informations mises à jour !',
                                colorClass: 'valid',
                            }),
                        },
                        onError,
                    },
                },
            },
        });
        const service = (0, vue_2.ref)((0, xstate_1.interpret)(machine));
        const uploadState = (0, vue_2.ref)(machine.initialState);
        (0, vue_2.onBeforeMount)(() => {
            service.value
                .onTransition(state => {
                uploadState.value = state;
            })
                .start();
        });
        function dropFile(event) {
            const files = event.target.files || event.dataTransfer.files;
            const file = files[0];
            service.value.send({
                type: 'DROP_FILE',
                file,
            });
            emit('file-dropped', file);
        }
        function isBelowMaxSize(file) {
            if (props.maxSize) {
                return !!file && file.size < props.maxSize;
            }
            return !!file;
        }
        return { __sfc: true, $sentry, $store, props, emit, onError, machine, service, uploadState, dropFile, isBelowMaxSize };
    }
});
