"use strict";
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.prospectFreelanceAddMutation = exports.ProspectFreelanceAddDocument = exports.prospectClientAddMutation = exports.ProspectClientAddDocument = void 0;
const graphql_vue_apollo_patch_1 = require("@frk/graphql-vue-apollo-patch");
const vue_apollo_smart_ops_1 = require("vue-apollo-smart-ops");
exports.ProspectClientAddDocument = (0, graphql_vue_apollo_patch_1.gql) `
    mutation prospectClientAdd($infos: ProspectClientAddInput!) {
  prospectClientAdd(infos: $infos)
}
    `;
/**
 * __prospectClientAddMutation__
 *
 * To run a mutation, you call `prospectClientAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = prospectClientAddMutation(this, {
 *   variables: {
 *     infos: // value for 'infos'
 *   },
 * });
 */
exports.prospectClientAddMutation = (0, vue_apollo_smart_ops_1.createMutationFunction)(exports.ProspectClientAddDocument);
exports.ProspectFreelanceAddDocument = (0, graphql_vue_apollo_patch_1.gql) `
    mutation prospectFreelanceAdd($infos: ProspectFreelanceAddInput!) {
  prospectFreelanceAdd(infos: $infos)
}
    `;
/**
 * __prospectFreelanceAddMutation__
 *
 * To run a mutation, you call `prospectFreelanceAddMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = prospectFreelanceAddMutation(this, {
 *   variables: {
 *     infos: // value for 'infos'
 *   },
 * });
 */
exports.prospectFreelanceAddMutation = (0, vue_apollo_smart_ops_1.createMutationFunction)(exports.ProspectFreelanceAddDocument);
