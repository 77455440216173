"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMPANY_FORM_OPTIONS = void 0;
const graphql_types_1 = require("@frk/graphql-types");
exports.COMPANY_FORM_OPTIONS = [
    { value: graphql_types_1.CompanyForm.Ei, label: 'Entreprise Individuelle' },
    { value: graphql_types_1.CompanyForm.Eirl, label: 'Entreprise Individuelle à Responsabilité' },
    { value: graphql_types_1.CompanyForm.Eurl, label: 'Entreprise Unipersonnelle à Responsabilité Limitée' },
    { value: graphql_types_1.CompanyForm.Sa, label: 'Société Anonyme' },
    { value: graphql_types_1.CompanyForm.Sarl, label: 'Société à Responsabilité Limitée' },
    { value: graphql_types_1.CompanyForm.Sas, label: 'Société par Actions Simplifiée' },
    { value: graphql_types_1.CompanyForm.Sasu, label: 'Société par Actions Simplifiée Unipersonnelle' },
    { value: graphql_types_1.CompanyForm.EiAeMe, label: 'EI / AE / ME (Obsolete)' },
    { value: graphql_types_1.CompanyForm.SarlEurl, label: 'EURL / SARL (Obsolete)' },
    { value: graphql_types_1.CompanyForm.SasSasu, label: 'SAS / SASU (Obsolete)' },
];
